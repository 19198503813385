import React, { useCallback } from 'react';
import { ROUTE_NAMES_DASHBOARD_EDIT } from '@atlassian/jira-common-constants/src/spa-routes';
import CreateDashboardModal from '@atlassian/jira-create-dashboard-modal/async';
import { useRouteByName } from '@atlassian/jira-platform-router-utils';
import { useRouterActions, generatePath } from '@atlassian/jira-router';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller';
import { MODAL_ID } from '../../../common/constants';
import { Absolute } from '../../../common/ui/absolute';

export const Dashboards = () => {
	const isAnonymous = useIsAnonymous();
	const [isOpen, { off: modalClose }] = useSwitchModals(MODAL_ID.DASHBOARD_CREATE);
	const { path } = useRouteByName(ROUTE_NAMES_DASHBOARD_EDIT) ?? {};
	const { push } = useRouterActions();
	const onSuccess = useCallback(
		// @ts-expect-error - TS7006 - Parameter '_' implicitly has an 'any' type. | TS7031 - Binding element 'id' implicitly has an 'any' type.
		(_, { id }) => {
			path && push(generatePath(path, { dashboardId: id }));
			modalClose();
		},
		[modalClose, path, push],
	);

	if (isAnonymous) {
		return null;
	}

	return isOpen ? (
		<Absolute>
			<CreateDashboardModal onSuccess={onSuccess} onCancel={modalClose} />
		</Absolute>
	) : null;
};
